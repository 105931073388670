import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from '../configs/app-insights';

export const appInsight = (function () {
  return {
    log: function (text: string) {
      appInsights.trackTrace({
        message: text,
        severityLevel: SeverityLevel.Warning,
      });
    },
  };
})();
