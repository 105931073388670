import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import './home.scss';
import Cross from '../../../assets/svg/cross.svg';

import Navbar from '../../../components/navbar/navbar';
import Button from '../../../components/button/button';
import RoundButton from '../../../components/round-button/round-button';
import MessageForm from '../../../components/message-form/message-form';
import ReportIssueForm from '../../../components/report-issue-form/report-issue-form';
import Wizard from '../../../components/wizard/wizard';

import { newGuid } from '../../../core/utilities/utils';

export function Home() {
  const { t } = useTranslation();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isFormSubmiting, setIsFormSubmiting] = useState(false);
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [visitorId, setVisitorId] = useState('');
  const [formType, changeFormType] = useState('contact');

  const wizardRef = useRef(null);

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    if (!(localStorage.getItem('guid'))) {
      localStorage.setItem('guid', newGuid());
    }

    setVisitorId(localStorage.getItem('guid'));
  }, []);


  return (
    <div
      className={ `home ${ isDrawerOpen ? 'slide-from-right' : 'slide-from-left' }` }
    >
      <RoundButton
        onClick={ () => {
          wizardRef.current.top();
          setTimeout(() => {
            setIsDrawerOpen(false);
            setIsFormSubmited(false);
          }, 500);
        } }
      >
        <img className="arrow-icon" src={ Cross } width={ 25 } height={ 25 } alt="close icon" />
      </RoundButton>
      <div className="main-section">
        <Navbar />
        <div className="welcome">
          <h1 className="primary">
            <span className="head">{ t('home.text.header') }</span>
            <div className="animation-container">
              <ul className="list">
                <li className="list__item">{ t('home.text.slide.content1') }</li>
                <li className="list__item">{ t('home.text.slide.content2') }</li>
                <li className="list__item">{ t('home.text.slide.content3') }</li>
              </ul>
            </div>
          </h1>
        </div>
        <div className="main-buttons">
          <Button onClick={ () => {
            setIsDrawerOpen(!isDrawerOpen);
            changeFormType('issue');
            appInsights.trackEvent({name: "Report an issue button click"});
          } } isOutline={ false }>
            <span>{ t('home.main_buttons.report') }</span>
          </Button>
          <Button onClick={ () => {
            setIsDrawerOpen(!isDrawerOpen);
            changeFormType('contact');
            appInsights.trackEvent({name: "Contact us button click"});
          } } center isOutline>
            <span>{ t('home.main_buttons.contact_us') }</span>
          </Button>
        </div>
      </div>
      <div
        className={ 'message-section ' }
      >
        { formType === 'contact' ? (
          <Wizard ref={ wizardRef } handleResetForm={ () => { } } type='contact' Component={ MessageForm } />
        ) : (
          <Wizard ref={ wizardRef } handleResetForm={ () => { } } type='issue' Component={ ReportIssueForm } />
        ) }
      </div>
    </div>
  );
}

export default Home;
