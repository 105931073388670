import * as React from 'react';

const MessageSentIconFigure = (props) => (
  <svg
    width={128}
    height={160}
    viewBox="0 0 128 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M127.726 40.9533L106.529 76.1942L64.3053 76.0932L43.2777 40.7512L64.4741 5.51024L106.698 5.61125L127.726 40.9533Z"
      fill="#E5E5E5"
      fillOpacity={0.57}
    />
    <path
      d="M47.8006 138.231L36.7098 156.671L14.6165 156.618L3.6141 138.126L14.7049 119.686L36.7981 119.739L47.8006 138.231Z"
      fill="#6CCBA6"
      fillOpacity={0.21}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.459 54.9834C8.51786 54.9834 0.458984 63.0423 0.458984 72.9834V112.878C0.458984 122.82 8.51785 130.878 18.459 130.878H70.8353L75.9796 140.093C79.577 146.537 88.9744 146.15 92.0302 139.433L96.0546 130.586C104.444 129.055 110.803 121.709 110.803 112.878V72.9834C110.803 63.0423 102.744 54.9834 92.8029 54.9834H18.459Z"
      fill="#6CCBA6"
    />
    <path
      d="M38.6524 84.7976C37.1962 83.1364 34.669 82.9702 33.0077 84.4265C31.3465 85.8827 31.1804 88.4099 32.6366 90.0711L38.6524 84.7976ZM44.9399 98.0379L41.932 100.675C43.3758 102.322 45.8759 102.501 47.5402 101.077L44.9399 98.0379ZM70.7785 81.1957C72.4572 79.7595 72.6537 77.2345 71.2176 75.5559C69.7814 73.8772 67.2564 73.6807 65.5778 75.1168L70.7785 81.1957ZM32.6366 90.0711L41.932 100.675L47.9477 95.4011L38.6524 84.7976L32.6366 90.0711ZM47.5402 101.077L70.7785 81.1957L65.5778 75.1168L42.3395 94.9985L47.5402 101.077Z"
      fill="white"
    />
  </svg>
);

export default MessageSentIconFigure;
