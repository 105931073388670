import React, { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import './message-form.scss';

import InputBox from '../input-box/input-box';
import PhoneNumberInput from '../phonenumber-input/phonenumber-input';
import Textarea from '../textarea/textarea';
import SubmitButton from '../submit-button/submit-button';

import { emailRegex, getBrowserLang, languages, nameRegex } from '../../core/utilities/utils';

interface MessageFormProps {
  handleCompleteForm: () => void;
}

interface MessageFormData {
  name: string;
  description: string;
  message: string;
  phone: string;
  email: string;
}

const MessageForm = ({ handleCompleteForm }: MessageFormProps) => {
  const phoneNumberInputRef = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    control, reset
  } = useForm<MessageFormData>({ mode: 'onChange' });
  const { t } = useTranslation();


  const onSubmit = (data: MessageFormData) => {
    return sendMessage(data)
      .then(
        () => { handleCompleteForm(); },
        () => { reset({name: '',description: '',email: '',message: '',phone:''}) }
        );
  };


  const sendMessage = async (data: MessageFormData) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const visitorContactData = JSON.stringify({
          email: data?.email,
          lang: languages[getBrowserLang().toString()],
          messageContent: data?.message,
          name: data?.name,
          phone: data?.phone,
          date: Date.now(),
          formType: 'message'
        });

        const result = await fetch("https://nonprod-apim-akashinga.azure-api.net/orchestra/send/message", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": process.env.OCP_APIM_SUBSCRIPTION_KEY,
            "Ocp-Apim-Trace": "true"
          },
          body: visitorContactData,
          mode: "cors"
        });
        console.log(visitorContactData);
        if (result?.ok) {
          resolve();
        } else {
          reject();
        }
      } catch (error) {
        console.log('Error', error)
      }
    });
  };


  return (
    <form noValidate className='b-messageform' onSubmit={ handleSubmit(onSubmit) }>
      <div className="b-messageform--container">
        <div className={ `b-messageform--container--fields ${ isSubmitting ? 'b-messageform--container--fields--overlay' : '' }` }>
          <legend>
            <h3 className='title'>{ t('contact_us_form.title') }</h3>
          </legend>
          <div className="message--inputs">
            <InputBox
              name={ 'name' }
              placeholder={ 'Balakiyem essowe' }
              label={ t('contact_us_form.name_input.label') }
              type="text"
              error={ errors?.name?.message }
              required
              { ...register('name', {
                required: {
                  value: true,
                  message: t('contact_us_form.name_input.error.required'),
                },
                minLength: {
                  value: 2,
                  message: t('contact_us_form.name_input.error.minLength'),
                },
                pattern: {
                  value: nameRegex,
                  message: t('contact_us_form.name_input.error.incorrect'),
                },
              }) }
            />
            <InputBox
              name={ 'email' }
              placeholder={ 'balakiyemessowe@gmail.com' }
              label={ t('contact_us_form.email_input.label') }
              type="email"
              required
              error={ errors?.email?.message }
              { ...register('email', {
                required: {
                  value: true,
                  message: t('contact_us_form.email_input.error.required'),
                },
                pattern: {
                  value: emailRegex,
                  message: t('contact_us_form.email_input.error.incorrect'),
                },
              }) }
            />
            <Controller
              control={ control }
              name="phone"
              rules={ {
                required: {
                  value: true,
                  message: 'enter your phone',
                },
                validate: () => phoneNumberInputRef.current?.getPhoneValidStatus(),
              } }
              render={ ({ field: { ref, onChange } }) => (
                <PhoneNumberInput
                  error={ errors?.phone }
                  label={ t('contact_us_form.phone_input.label') }
                  ref={ phoneNumberInputRef }
                  onChange={ onChange }
                  required={ true }
                />
              ) }
            />
            <Textarea
              required
              placeholder={ t('contact_us_form.message_input.placeholder') }
              name={ 'message' }
              error={ errors?.message?.message }
              { ...register('message', {
                required: {
                  value: true,
                  message: t('contact_us_form.message_input.error.required'),
                },
              }) }
            />
          </div>
        </div>
        <SubmitButton disabled={ !isValid } submitting={ isSubmitting } />
      </div>
    </form>
  );
};

export default MessageForm;