import * as React from 'react';

const IssueSentFigure = (props) => (
    <svg
        width={ 159 }
        height={ 137 }
        viewBox="0 0 159 137"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        { ...props }
    >
        <path
            d="M129.865 30.5471L139.225 72.0682L107.574 102.201L66.5627 90.8132L57.2026 49.2921L88.8538 19.1591L129.865 30.5471Z"
            fill="#6CCBA6"
            fillOpacity={ 0.19 }
        />
        <path
            d="M139.014 24.356L129.869 39.5595L111.653 39.516L102.581 24.2688L111.726 9.06527L129.942 9.10885L139.014 24.356Z"
            fill="#0C586C"
            fillOpacity={ 0.19 }
        />
        <path
            d="M28.9688 68.0297L38.5763 80.4489L41.9032 100.861L49.1656 95.769L56.4159 106.573L120.804 45.6013L28.9688 68.0297Z"
            fill="#6CCBA6"
        />
        <path
            opacity={ 0.3 }
            d="M49.1654 95.7689L45.7857 98.1383L43.96 85.5406L49.1654 95.7689Z"
            fill="black"
        />
        <path
            opacity={ 0.3 }
            d="M38.5771 80.4489L120.805 45.6013L43.9611 85.5407L41.9041 100.861L38.5771 80.4489Z"
            fill="black"
        />
        <path
            d="M1.25876 136.5C0.44807 131.332 1.07636 120.388 10.075 117.956C21.3234 114.915 27.4037 120.388 28.3157 130.116C29.2277 139.844 -7.55738 124.036 34.3959 100.931"
            stroke="#0C586C"
            strokeDasharray="2 2"
        />
    </svg>
);

export default IssueSentFigure;
