/* eslint @typescript-eslint/no-unused-expressions: ["off"] */
import React, { useState, useEffect } from 'react';

import FeatureFlagItem from '../../components/featureflagitem/featureflagitem';
import './index.scss';
import sortIcon from '../../assets/svg/sort.svg';
import { appInsight } from '../../core/services/app-insights';


interface FeatureFlag {
  appName: string;
  dateAdded: Date;
  dateEnabled: Date;
  description: string;
  enabled: boolean;
  featureFlagId: string;
  featureFlagname: string;
  featurePage: string;
}
 

export function Index() {  
  const [featureFlagsList, setFeatureFlagsList] = useState({});
  const [featureFlagsCheckedList, seFeatureFlagsCheckedList] = useState([]);
  const [allFeatureFlagsAreChecked, setAllFeatureFlagsAreChecked] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [allSwitchBoxSwitched, setAllSwitchBoxSwitched] = useState(false);
  
  //API REQUESTS FUNCTIONS
  const getAllFeatureFlags = () => {
    fetch('http://localhost:7071/api/featureflags/kioskportal')
      .then(async (res) => {
        const response = await res.json();
        const featureFlags: FeatureFlag[] = response.body.featureFlags;
        const formatedFeatureFlags = featureFlags.reduce((obj, item) => {
          obj[item.featureFlagname] = item;
          return obj;
        }, {});

        setFeatureFlagsList(formatedFeatureFlags);
            
      }).catch((err) => {
        console.log(err);
            
      });
  };

  useEffect(() => {
    getAllFeatureFlags();
  }, []);

  useEffect(() => {
    if (featureFlagsCheckedList.length !== 0) {
      if (featureFlagsCheckedList.length === Object.values(featureFlagsList).length) {
        setCheckAll(true);
        setAllFeatureFlagsAreChecked(true);
      } else {
        setAllFeatureFlagsAreChecked(false);
      }
    }
  }, [featureFlagsCheckedList, featureFlagsList]);

  const onefeatureFlagSwitched = (featureFlag) => {
    const ffList = { ...featureFlagsList };
    ffList[featureFlag.featureFlagName].enabled = !ffList[featureFlag.featureFlagName].enabled;
    setFeatureFlagsList(ffList);
  };

  const oneFeatureFlagChecked = ({ featureFlag, add }) => {
    if (add === true) {
      seFeatureFlagsCheckedList([...featureFlagsCheckedList, featureFlag]);
        
    } else {
      seFeatureFlagsCheckedList(
        featureFlagsCheckedList.filter((ff) => {
          return ff.featureFlagName !== featureFlag.featureFlagName;
        }),
      );
    }
  }; 

  const allFeatureFlagsChecked = () => {
    setAllFeatureFlagsAreChecked(!allFeatureFlagsAreChecked);
    setCheckAll(!checkAll);
    allFeatureFlagsAreChecked === false
      ? seFeatureFlagsCheckedList(Object.values(featureFlagsList))
      : seFeatureFlagsCheckedList([]);
  };

  const allFeatureFlagsSwitched = () => {
    setAllSwitchBoxSwitched(!allSwitchBoxSwitched);
    if (featureFlagsCheckedList.length > 0) {
      const ffList = { ...featureFlagsList };
      featureFlagsCheckedList.map((ff) => {
        return ffList[ff.featureFlagName].enabled = !allSwitchBoxSwitched;
      });
      setFeatureFlagsList(ffList);
    }
    
  };

  return (
    <div className="feature-flags">
      <div className="actions-bar">
        <label htmlFor="box" className="text">
          Set selected
        </label>
        <label className="switch">
          <input type="checkbox" checked={allSwitchBoxSwitched} onChange={allFeatureFlagsSwitched}/>
          <span className="slider round"></span>
        </label>
      </div>

      <div className="header">
        <label className="select-all">
          <input type="checkbox" id="select" checked={allFeatureFlagsAreChecked} onChange={allFeatureFlagsChecked}/>
          <span className="mark"></span>
        </label>

        <span className="name col">
          <img src={sortIcon} alt="sort icon" className="sorticon" />
          Name
        </span>

        <span className="desc col">
          <img src={sortIcon} alt="sort icon" className="sorticon" />
          Description
        </span>

        <span className="group col">
          <img src={sortIcon} alt="sort icon" className="sorticon" />
          Epic
        </span>

        <span className="date col">
          <img src={sortIcon} alt="sort icon" className="sorticon" />
          Date
        </span>
      </div>

      <div className="content">
        {Object.values(featureFlagsList).map((featureFlag, id) => (
          <FeatureFlagItem key={id} checked={checkAll} oneFeatureFlagChecked={oneFeatureFlagChecked} onefeatureFlagSwitched={onefeatureFlagSwitched} featureFlag={featureFlag} />
        ))}
      </div>
    </div>
  );
}

export default Index;
