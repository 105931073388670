import React, { forwardRef } from 'react';

import './radio.scss';


// export interface RadioProps {
//   onChange: any;
//   onBlur: any;
//   name: string;
//   value: string;
//   label: string;
//   index: number;
//   required: boolean;
//   isChecked?: boolean;
// }



export const Radio = forwardRef(({ required, name, onChange, onBlur, value, label, index, isChecked }: any, ref) => {
  return (
    <fieldset className='b-radiofield'>
      <input checked={ isChecked } ref={ref} id={ `${ name }_${ index }` } type='radio' className='b-radiofield--input' name={ name } onChange={ onChange } onBlur={onBlur} value={ value } required={ required } />
      <label className='b-radiofield--label' htmlFor={ `${ name }_${ index }` }>{ label }</label>
    </fieldset>
  );
});


export default Radio;
