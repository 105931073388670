import React, { forwardRef } from 'react';

import './textarea.scss';

export interface TextareaProps {
  name: string;
  required: boolean;
  placeholder: string;
  error: string;
  onChange: any;
  onBlur: any;
}

export const Textarea = forwardRef(({ placeholder, error, onChange, onBlur, name }: TextareaProps, ref) => {
  return (
        <fieldset className='b-textareafield'>
            <textarea
                ref={ ref as React.LegacyRef<HTMLTextAreaElement> }
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                className="b-textareafield--textarea"
                id="message-textarea"
                placeholder={ placeholder }
                autoComplete="off"
            />
            <span className="b-textareafield--errormessage">{ (error) }&nbsp;</span>
        </fieldset>
  );
});

export default Textarea;
