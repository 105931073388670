import React, { useState, useEffect } from "react";
import "./not-found.scss";
import image from "../assets/images/backgimage.webp";

const FRICTION = 1 / 30;
export function NotFound() {
  const [coords, setCoords] = useState({
    x: 0,
    y: 0,
  });
  const [translate, setTranslate] = useState("");
  const [follow, setFollow] = useState({
    x: 0,
    y: 0,
  });

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setCoords({ x: coords.x + ((follow.x - coords.x) * FRICTION), y: coords.y + ((follow.y - coords.y) * FRICTION) });
    const lMouseX = Math.max(-100, Math.min(100, window.innerWidth / 2 - e.clientX));
    const lMouseY = Math.max(-100, Math.min(100, window.innerHeight / 2 - e.clientY));
    setFollow({ x: (20 * lMouseX) / 100, y: (10 * lMouseY) / 100 });
    setTranslate('translate(' + coords.x + 'px, ' + coords.y + 'px) scale(1.1)');
  };

  return (
    <div
      className="not-found"
      onMouseMove={ handleMouseMove }
    >
      <div className="content">
          <h1>404</h1>
          <h2>Page not found</h2>
          <p>There seems to be nothing here but let's look harder together.</p>
          <button><a href="/">back to home</a></button>
      </div>
      <img style={ { transform: translate } } src={ image } alt="not-found" />
    </div>
  );
}

export default NotFound;
