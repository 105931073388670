
export const nameRegex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;


export const languages = {
  en: 'English',
  fr: 'French',
};

export function newGuid(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const findOtp = (guid: string) => {
  const fetchedOtp = guid.slice(2, 4) + guid.slice(15, 17) + guid.slice(-1) + guid.charAt(21);
  return fetchedOtp;
};

export const isNameValid = (name: string): boolean => {
  const response = nameRegex.test(name);
  return response === true ? true : false;
};

export const isEmailValid = (email: string): boolean => {
  const response = emailRegex.test(email.toLowerCase());
  return response === true ? true : false;
};

export const getPhone = (str: string): string => str.replace(/\s+/g, '');


// I18next functions
export function isBrowser() {
  return typeof window !== 'undefined';
}

function getBrowserCultureLang() {
  if (isBrowser() === false) {
    return undefined;
  }
  const navigator = ((window.navigator));
  let browserCultureLang = navigator.languages ? navigator.languages[0] : null;
  browserCultureLang = browserCultureLang || navigator.language || navigator.browserLanguage || navigator.userLanguage;
  return browserCultureLang;
}
export function getBrowserLang() {
  let browserLang = getBrowserCultureLang();
  if (isBrowser() === false || !browserLang) {
    return undefined;
  }
  if (browserLang.indexOf('-') !== -1) {
    browserLang = browserLang.split('-')[0];
  }
  if (browserLang.indexOf('_') !== -1) {
    browserLang = browserLang.split('_')[0];
  }
  return browserLang;
}


