import './navbar.scss';
import ZovuLogo from '../../assets/svg/logo.svg';
import Facebook from '../../assets/svg/facebook.svg';
import Youtube from '../../assets/svg/youtube.svg';
import Twitter from '../../assets/svg/twitter.svg';
import Instagram from '../../assets/svg/instagram-full.svg';
import LinkedIn from '../../assets/svg/linkedin.svg';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NavbarProps {}

export function Navbar() {
  return (
    <div className="navbar">
      <div className="left">
        <img className="logo" src={ZovuLogo} alt="Zovu Logo" />
      </div>
      <div className="right">
        <div className="media-icon-container">
          <div className="media-icon-box">
            <a href="https://twitter.com/ZovuSolutions">
              <img
                className="social-icon"
                src={Twitter}
                alt="twitter social icon"
              />
            </a>
          </div>
          <div className="media-icon-box">
            <a href="https://instagram.com/zovusolutions?utm_medium=copy_link">
              <img
                className="social-icon"
                src={Instagram}
                alt="instagram social icon"
              />
            </a>
          </div>
          <div className="media-icon-box">
            <a href="https://www.youtube.com/channel/UCMAkWlpoZAiKjkPZIuHqIFw">
              <img
                className="social-icon"
                src={Youtube}
                alt="youtube social icon"
              />
            </a>
          </div>
          <div className="media-icon-box">
            <a href="https://lnkd.in/eyxyUGBh">
              <img
                className="social-icon"
                src={LinkedIn}
                alt="linkedIn social icon"
              />
            </a>
          </div>
          <div className="media-icon-box">
            <a href="https://www.facebook.com/ZovuSolutions">
              <img
                className="social-icon"
                src={Facebook}
                alt="facebook social icon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
