import React, { useState, useEffect } from 'react';

import './featureflagitem.scss';

export function FeatureFlagItem({ featureFlag, onefeatureFlagSwitched, oneFeatureFlagChecked, checked }) {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const featureFlagSwitched = () => {
    onefeatureFlagSwitched(featureFlag);
  };

  const featureFlagChecked = () => {
    setIsChecked(!isChecked);
    oneFeatureFlagChecked({ 'featureFlag': featureFlag, 'add': isChecked === false ? true : false });
  };

  const formatDate = (date) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    return `${new Date(date).getDate()} 
                ${months[new Date(date).getMonth()]}'${new Date(date).getFullYear().toString().slice(2, 4)}`;
  };

  return (
        <div className={`feature ${featureFlag?.enabled ? 'feature-activated' : 'feature-deactivated'}`}>
            <div className="actions">
                <label className="container">
                    <input type="checkbox" id="box-select" checked={isChecked} onChange={featureFlagChecked}/>
                    <span className="checkmark"></span>
                </label>

                <div className="switch-container">
                    <label className="switch">
                        <input type="checkbox" checked={featureFlag?.enabled} onChange={featureFlagSwitched}/>
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>
            
            <span className="name"><span className="delimiter"></span>{ featureFlag?.featureFlagName }</span>
            <span className="desc">
                <span className="delimiter"></span> 
                { featureFlag?.description.slice(0, 300) }
            </span>
            <span className="group"><span className="delimiter"></span>{ featureFlag?.featurePage }</span>
            <span className="date"><span className="delimiter"></span>{ formatDate(featureFlag?.dateAdded) }</span>
        </div>
  );
}

export default FeatureFlagItem;