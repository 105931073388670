import React, { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import './report-issue-form.scss';

import RadioInput from '../radio/radio';
import InputBox from '../input-box/input-box';
import PhoneNumberInput from '../phonenumber-input/phonenumber-input';
import Textarea from '../textarea/textarea';
import SubmitButton from '../submit-button/submit-button';

import { emailRegex, getBrowserLang, languages } from '../../core/utilities/utils';

export interface ReportIssueFormProps {
    handleCompleteForm: () => void;
}

interface ReportFormData {
    issueType: string;
    otherIssueDescription?: string;
    phone: string;
    email?: string;
}

export function ReportIssueForm({ handleCompleteForm }: ReportIssueFormProps) {
    const { t } = useTranslation();
    const phoneNumberInputRef = useRef(null);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isSubmitting, isValid },
        control,
    } = useForm<ReportFormData>({ mode: 'onChange' });
    const issueWatcher = watch('issueType');

    const onSubmit = (data) => {
        return sendMessage(data)
            .then(
                () => { handleCompleteForm(); }
            );
    };

    const sendMessage = async (data: ReportFormData) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const visitorIssueData = JSON.stringify({
                    email: data?.email || undefined,
                    lang: languages[getBrowserLang().toString()],
                    phone: data?.phone,
                    date: Date.now(),
                    formType: 'issue',
                    issue: {
                        type: data?.issueType,
                        issueDescription: data?.issueType === 'other' && data?.otherIssueDescription
                    }
                });
                const result = await fetch("https://nonprod-apim-akashinga.azure-api.net/orchestra/send/issue", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Ocp-Apim-Subscription-Key": process.env.OCP_APIM_SUBSCRIPTION_KEY,
                        "Ocp-Apim-Trace": "true"
                    },
                    body: visitorIssueData,
                    mode: "cors"
                });
                if (result?.ok) {
                    resolve();
                } else {
                    reject();
                }
            } catch (error) {
                console.log('Error', error)
            }
        });
    };


    return (
        <form noValidate className='b-reportissueform' onSubmit={ handleSubmit(onSubmit) }>
            <div className="b-reportissueform--container">
                <div className={ `b-reportissueform--container--fields ${ isSubmitting ? 'b-reportissueform--container--fields--overlay' : '' }` }>
                    <legend>
                        <h3 className='title'>{ t('report_issue_form.title') }</h3>
                    </legend>
                    <div className='radio-group'>
                        <legend>
                            { !issueWatcher && <h6 className='radio-label'>{ t('report_issue_form.issue_type_input.message') }</h6> }
                        </legend>
                        <RadioInput
                            index={ 1 }
                            value={ 'phone' }
                            label={ t('report_issue_form.issue_type_input.phone_radio_input.label') }
                            { ...register('issueType', { required: true }) }
                        />
                        <RadioInput
                            index={ 2 }
                            value={ 'other' }
                            { ...register('issueType', { required: true }) }
                            label={ t('report_issue_form.issue_type_input.other_radio_input.label') }
                        />
                        {
                            issueWatcher === 'other' && (
                                <Textarea
                                    placeholder={ t('report_issue_form.description_input.placeholder') }
                                    name="otherIssueDescription"
                                    { ...register('otherIssueDescription', { required: issueWatcher === 'other' }) }
                                    error={ errors?.otherIssueDescription?.message }
                                    required
                                />
                            )
                        }
                    </div>
                    <div className='contact--container'>
                        <h4 className='contact--title'>{ t('report_issue_form.contact') }</h4>
                        <div className="contact--inputs">
                            <Controller
                                control={ control }
                                name="phone"
                                rules={ {
                                    required: {
                                        value: true,
                                        message: t('report_issue_form.phone_input.error.required'),
                                    },
                                    validate: () => phoneNumberInputRef.current?.getPhoneValidStatus(),
                                } }
                                render={ ({ field: { ref, onChange } }) => (
                                    <PhoneNumberInput
                                        error={ errors?.phone }
                                        label={ t('report_issue_form.phone_input.label') }
                                        ref={ phoneNumberInputRef }
                                        onChange={ onChange }
                                        required={ true }
                                    />
                                ) }
                            />
                            <InputBox
                                name={ 'email' }
                                placeholder={ 'balakiyemessowe@gmail.com' }
                                label={ t('report_issue_form.email_input.label') }
                                type="email"
                                { ...register('email', {
                                    pattern: {
                                        value: emailRegex,
                                        message: t('report_issue_form.email_input.error.incorrect'),
                                    },
                                }) }
                                error={ errors?.email?.message } />
                        </div>
                    </div>
                </div>
                <SubmitButton disabled={ !isValid || isSubmitting } submitting={ isSubmitting } />
            </div>
        </form>
    );
}

export default ReportIssueForm;
