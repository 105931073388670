import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getBrowserLang } from '../utilities/utils';

import frTranslation from '../../assets/i18n/fr.json';
import enTranslation from '../../assets/i18n/en.json';

const resources = {
  fr: { translation: frTranslation },
  en: { translation: enTranslation },
};

const lang = getBrowserLang();

i18n
  .use(initReactI18next)
  .init({
    debug: false,
    resources,
    supportedLngs: ['en', 'fr'],
    lng: lang,
    fallbackLng: 'en',
    keySeparator: '.',
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;
