import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { globalHistory } from '@reach/router';
import { isBrowser } from '../utilities/utils';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      'InstrumentationKey=a1452f6f-4182-4a0e-bf3a-2da035492529;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/',
    enableDebug: true,
    isBrowserLinkTrackingEnabled: true,
    loggingLevelConsole: 2,
    loggingLevelTelemetry: 2,
    autoTrackPageVisitTime: true,
    disableFetchTracking: false,
    disableTelemetry: isBrowser() ? (window.location.hostname === 'localhost') : true,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: globalHistory },
    },
  },
});

appInsights.loadAppInsights();
export { reactPlugin, appInsights };
