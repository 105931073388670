import './round-button.scss';

/* eslint-disable-next-line */
export interface RoundButtonProps {
  onClick: () => void;
  children: any;
}

export function RoundButton(props: RoundButtonProps) {
  return (
    <div className="round-button" onClick={ props.onClick }>
      { props.children }
    </div>
  );
}

export default RoundButton;
