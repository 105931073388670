import React from 'react';
import './button.scss';

export interface ButtonProps {
  onClick?: () => void;
  children: any;
  isOutline?: boolean | false;
  center?: boolean | false;
  type?: 'button' | 'submit'
}

export function Button(props: ButtonProps) {

  return (
    <button onKeyUp={ e => e.preventDefault() } tabIndex={ -1 } className={ `b-button ${ props.isOutline ? 'outline' : '' }` } type={ props.type } onClick={ (e) => {
      props.onClick();
    } }>
      { props.children }
    </button>
  );
}

export default Button;
