import React, { useEffect } from 'react';
import '../core/services/i18n';
import './index.scss';

import SEO from '../components/seo/seo';
import Home from '../components/pages/home/home';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../core/configs/app-insights';
import { appInsight } from '../core/services/app-insights';


export function Index() {

  useEffect(() => {
    appInsight.log('Corporate Site started');
  }, []);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <SEO title='Zovu Solutions | Corporate Site'/>
      <Home />
    </AppInsightsContext.Provider>
  );
}

export default Index;
