import React from 'react';
import { useTranslation } from 'react-i18next';

import './submit-button.scss';

import ActivityIndicator from '../activity-indicator/activity-indicator';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

interface SubmitButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  submitting: boolean;
  disabled: boolean;
}

const SubmitButton = ({
  submitting,
  disabled = true,
  ...props
}: SubmitButtonProps) => {
  const appInsights = useAppInsightsContext();
  const { t } = useTranslation();
  if (submitting)
    return (
      <button
        type="submit"
        className="b-submitbutton--button --outline --sending"
      >
        <ActivityIndicator />
        <div>{t('contact_us_form.submit_button.sending')}</div>
      </button>
    );
  return (
    <button
      {...props}
      type="submit"
      onClick={() => {
        appInsights.trackEvent({ name: 'Form Send button click' });
      }}
      disabled={disabled}
      className={'b-submitbutton--button --active'}
    >
      {t('contact_us_form.submit_button.send')}
    </button>
  );
};

export default SubmitButton;
