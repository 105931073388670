import React, { forwardRef } from 'react';

import './input-box.scss';

export interface InputBoxProps {
  label: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  required?: boolean;
  type: 'search' | 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal';
  value?: string;
  error: string;
}

export const InputBox = forwardRef(({ name, onChange, required = false, type, error, label, placeholder }: InputBoxProps, ref) => {

  const ShowOptional = () => {
    if (!required) {
      return <span className='b-fieldset--label--optional'>(optional)</span>;
    }
    return null;
  };


  return (
    <fieldset aria-autocomplete='none' className='b-fieldset'>
      <label className='b-fieldset--label' htmlFor={ `${ name }_input` }>{ label } {<ShowOptional />}</label>
      <input
        ref={ ref as React.LegacyRef<HTMLInputElement> }
        autoComplete="off"
        id={ `${ name }_input` }
        type={ type }
        inputMode={ type }
        name={ name }
        className={ `b-fieldset--input ${(error) ? 'error' : '' }` }
        placeholder={ placeholder }
        required={ required }
        onChange={ onChange }
        onBlur={ onChange }
      />
      <span className="b-fieldset--errormessage">{ error }&nbsp;</span>
    </fieldset>
  );
});

export default InputBox;
