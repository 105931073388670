import React, { forwardRef, useState, useImperativeHandle, useRef } from 'react';
import MessageSent from '../message-sent/message-sent';
import './wizard.scss';

interface WizardProps {
  Component: any;
  type: 'issue' | 'contact';
  handleResetForm: () => void;
}

const Wizard = forwardRef(({ Component, type, handleResetForm }: WizardProps, ref) => {
  const [val, setVal] = useState('');

  useImperativeHandle(ref, () => ({
    bottom() {
      setVal('b-wizard--slide-bottom-top');
    },
    top() {
      setVal('b-wizard--slide-top-bottom');
    },
  }));

  const bottom = () => {
    setVal('b-wizard--slide-bottom-top');
  };
  const top = () => {
    setVal('b-wizard--slide-top-bottom');
  };

  return (
    <div  className={ `b-wizard ${ val }` }>
        <div className="b-wizard--top">
          <Component handleCompleteForm={ bottom } />
        </div>
        <div className="b-wizard--bottom">
          <MessageSent type={ type } onResendClicked={ () => { top(); handleResetForm(); } } />
        </div>
      </div>
  );
},
);

export default Wizard;