import React, { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorOption } from 'react-hook-form';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

import './phonenumber-input.scss';

import useStateCallback from '../../core/hooks/useStateCallback';
import { getPhone } from '../../core/utilities/utils';

export interface PhoneNumberInputProps {
  label: string;
  onChange: (str: string) => void;
  required: boolean;
  error: ErrorOption;
}


export const PhoneNumberInput = forwardRef(({ label, required, error, onChange }: PhoneNumberInputProps, ref) => {
  const { t } = useTranslation();
  const [isPhoneValid, setIsPhoneValid] = useStateCallback(false);
  useImperativeHandle(ref, () => ({
    getPhoneValidStatus(): boolean {
      return isPhoneValid;
    },
  }));

  const handlePhoneNumberChange = (
    isValid,
    _rawValue,
    countryData,
    formattedValue,
    extension,
  ) => {
    setIsPhoneValid(isValid, () => {
      let phoneNumber = getPhone(formattedValue);
      onChange(phoneNumber);
    });
  };

  return (
        <fieldset className="b-phonefield">
            <label className={ 'b-phonefield--label' }>{ label }</label>
            <IntlTelInput
                onPhoneNumberChange={ handlePhoneNumberChange }
                onPhoneNumberBlur={ handlePhoneNumberChange }
                separateDialCode={ true }
                containerClassName='intl-tel-input'
                inputClassName={ `b-phonefield--input ${ (error) ? 'error' : '' }` }
                fieldName="phone"
                fieldId="phone"
                preferredCountries={ ['US', 'TG'] }
                useMobileFullscreenDropdown={ false }
                defaultCountry='tg'
                format={ true }
                telInputProps={ {
                  required: required,
                } }
            />
            <span className="b-phonefield--errormessage">{ error?.type === 'validate' && t('report_issue_form.phone_input.error.incorrect') } &nbsp;</span>
        </fieldset>
  );
});

export default PhoneNumberInput;
