import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import Logo from '../../assets/images/logo.png';

export function SEO({ description, lang, meta }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            authors
            keywords
            lang
            siteUrl
            image
          }
        }
      }
    `,
  );

  const metaTitle = site.siteMetadata?.title;
  // const authors = site.siteMetadata.authors;
  const keywords = site.siteMetadata.keywords;
  const metaDescription = description || site.siteMetadata.description;
  const metaLang = lang || site.siteMetadata.lang;
  const siteUrl = site.siteMetadata.siteUrl;
  // const image = site.siteMetadata.image;

  return (
    <Helmet
      charSet="utf-8"
      htmlAttributes={{
        metaLang,
      }}
      title={metaTitle}
      meta={[
        {
          name: 'description',
          content: `${metaDescription}`,
        },
        {
          name: 'keywords',
          content: `${keywords}`,
        },
        {
          property: 'og:title',
          content: `${metaTitle}`,
        },
        {
          property: 'og:description',
          content: `${metaDescription}`,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:siteUrl',
          content: `${siteUrl}`,
        },
        {
          property: 'og:image',
          content: `${siteUrl}/${Logo}`,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
