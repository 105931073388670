import React from 'react';
import { useTranslation } from 'react-i18next';

import './message-sent.scss';

import { Button } from '../button/button';
import IssueSentFigure from '../../assets/svg/issue-sent-figure';
import MessageSentIconFigure from '../../assets/svg/message-sent-figure';

export interface MessageSentProps {
  type: 'issue' | 'contact';
  onResendClicked: () => void;
}

export function MessageSent(props: MessageSentProps) {
  const { t } = useTranslation();

  const Figure = () => {
    return (props.type === 'issue') ? (<IssueSentFigure className={ 'b-messagesent--wrapper--image' } />) : (
      <MessageSentIconFigure className={ 'b-messagesent--wrapper--image' } />
    );
  };

  const Message = () => {
    return (props.type === 'issue') ? (
      <span>{ t('report_sent.button') }</span>
    ) : (
        <span>{ t('message_sent.button') }</span>
    );
  };

  const TitleAndSubtitle = () => {
    return (props.type === 'issue' ) ? (
      <>
        <h2 className="title">{ t('report_sent.message') }</h2>
        <p className='subtitle'>{ t('report_sent.subtitle') }</p>
      </>
    ) : (
        <>
          <h2 className="title">{ t('message_sent.message') }</h2>
          <p className='subtitle'>{ t('message_sent.subtitle') }</p>
        </>
    );
  };

  return (
    <div className="b-messagesent" tabIndex={ -1 }>
      <div className="b-messagesent--wrapper">
        <div className="b-messagesent--wrapper--imagecontainer">
          <Figure />
        </div>
        <div className="b-messagesent--wrapper--content">
          <TitleAndSubtitle />
        </div>
        <div className="b-messagesent--wrapper--actions">
          <button className='b-message-sent-button' onClick={ () => { props.onResendClicked(); } }>
            <Message />
          </button>
        </div>
      </div>
    </div>
  );
}

export default MessageSent;
